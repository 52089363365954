import React, { useState, useEffect } from 'react';
import VideoApp from './VideoApp';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import LogOutIcon from './libs/bootstrap-icons-1.11.3/box-arrow-right.svg';
import LoginForm from './LoginForm';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const rememberedAuth = localStorage.getItem('authData');
    if (rememberedAuth) {
      const authData = JSON.parse(rememberedAuth);
      const now = new Date();
      if (now.getTime() < authData.expiry) {
        setIsAuthenticated(true);
      } else {
        localStorage.removeItem('authData');
      }
    }
  }, []);

  const handleLogin = (passphrase, rememberMe) => {
    const validPassphrase = 'PlayMyVideo';

    if (passphrase === validPassphrase) {
      setIsAuthenticated(true);
      if (rememberMe) {
        const now = new Date();
        const item = {
          value: true,
          expiry: now.getTime() + 7 * 24 * 60 * 60 * 1000, // Remember for 7 days
        };
        localStorage.setItem('authData', JSON.stringify(item));
      }
    } else {
      alert('Invalid passphrase');
    }
  };

  const handleSignOut = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('authData');
  };

  return (
    <div className={`app-container ${isAuthenticated ? '' : 'full-screen'}`}>
      {isAuthenticated ? (
        <>
         <nav className="full-width-header">
            <div className="navbar-container">
              <div className="demo-text-container">
              <span className="demo-text bold-text">Simple Media Player</span>
              </div>
              <button 
                className="btn btn-link text-light logout-btn" 
                onClick={handleSignOut} 
                title="Sign Out"
              >
                <img src={LogOutIcon} alt="Sign Out" className="logout-icon" />
              </button>
            </div>
          </nav>
          <div className="content">
            <div className="app-content">
              <VideoApp />
            </div>
          </div>
          <footer className="footer">
            <div className="container text-center mx-auto">
              <span className="version-text">v1.1.0 - demo use only</span>
            </div>
          </footer>
        </>
      ) : (
        <LoginForm onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;